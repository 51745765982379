import React from 'react'

import Component from './style'
import { Heading, Stack } from 'components'

export default function Price({ price }: { price: number | string }) {
  return (
    <Component>
      <Stack gridTemplateColumns="repeat(2, auto)" justifySelf="flex-end">
        <Heading variant="sm">Total Price</Heading>
        <Heading variant="sm">${price} </Heading>
      </Stack>
    </Component>
  )
}
